import React from "react"
import LoginAreaVendor from "../../components/Auth/LoginAreaVendor";

function LoginVendor() {
  return (
    <LoginAreaVendor />
  );
}

export default LoginVendor;
