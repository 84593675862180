function Category() {
  return (
    <ul className="slider-categories">
      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-desktop-computer"></i>
          Computers & Accessories
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-stereo"></i>
          Audio & Home Theater
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-laptop"></i>
          Laptop
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-tv-box"></i>
          TV & Accessories
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-smartphone"></i>
          Mobiles & Tablets
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-headphones"></i>
          Headphone & Earphone
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-battery-charge"></i>
          Battery & Accessories
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-smart-watch"></i>
          Watches
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-camera"></i>
          Cameras
        </a>
      </li>

      <li>
        <a href="#" className="nav-link">
          <i className="flaticon-trimmer"></i>
          Accessories
        </a>
      </li>
    </ul>
  );
}

export default Category;
