import "./addproducts.scss"
import Sidebar from "../../../components/Ecommerce/Sidebar/Sidebar";
import Navbar from "../../../components/Ecommerce/Navbar/Navbar"
import ProductForm from "./ProductForm"

const AddProducts = () => {
  return (
    <div className="addproducts">
    {/*  <div className=""> */}
        <Sidebar dropTheMenu={true} colorMenuProducts={true}/>
        {/* <div className=""> */}
        <div className="listContainer">
            <Navbar/>
            {/* BEGINS FORM */}
            <ProductForm/>
            {/* ENDS FORM */}
      </div>
    </div>
  )
}

export default AddProducts